'use client'

import { Grid, Typography } from '@mui/material'

// Types
import type { TitleProps } from '@/types/components/atoms/title'

// Helpers
import { useTranslation } from '@/helpers/i18n/client'

// Utils
import Camelize from '@/utils/camelize'

// Metadata
const TitleAtom = (props: TitleProps) => {
	// Props
	const { lng, title, options } = props

	// Variables
	const { t } = useTranslation(lng)

	// Options
	if (options) for (const index in options) options[index] = t(options[index])

	return (
		<Grid container>
			<Typography variant="h4" component="h1" mt={2}>
				{options ? t(Camelize(title), options) : t(Camelize(title))}
			</Typography>
		</Grid>
	)
}

export default TitleAtom
